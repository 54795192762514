<template>
  <iframe
    ref="player_iframe"
    v-bind="$attrs"
    referrerpolicy="unsafe-url"
    :srcdoc="embed"
    allowfullscreen
    loading="eager"
    class="fullwidth"
    @load="onLoad"
    allowtransparency
    allow="autoplay"
  ></iframe>
</template>

<script>
export default {
  name: 'EmbedPlayer',
  props: {
    embed: { type: String }
  },
  computed: {
    modifiedEmbed() {
      return this.embed && this.embed.includes('<iframe') ? null : this.embed
    }
  },
  beforeDestroy() {
    this.resetIFrame()
  },
  methods: {
    onLoad() {
      console.log(e)
    },
    resetIFrame() {
      const iframe = this.$refs.player_iframe
      if (iframe) {
        iframe.src = iframe.src
      }
    }
  }
}
</script>

<style lang="scss"></style>
