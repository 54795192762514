var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomDialog',{attrs:{"title":_vm.title,"max-width":_vm.lgAndUp ? 1904 : 1366,"button1-text":"close","has-footer":false},on:{"button1":_vm.close_dialog},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.item)?_c('v-sheet',{key:_vm.item.id,staticClass:"fullwidth",attrs:{"height":"100%"}},[_c('v-row',{attrs:{"no-gutters":"","dense":"","align":"start","justify":"space-between"}},[(!_vm.annotate_mode)?_c('v-sheet',{staticClass:"col-md-7 col-sm-12 d-flex rounded-5 pa-5 flex-column align-center justify-center",attrs:{"height":"500","tag":"v-col","color":"#c0c0c0"}},[(_vm.canBeViewInAttachment)?_c('Attachment',{staticClass:"align-self-center",attrs:{"height":"420","animated":"","item":_vm.item}}):(
              _vm.is_link(_vm.item) &&
              (_vm.isYouTube(_vm.item.link_url) || _vm.isVimeo(_vm.item.link_url))
            )?_c('WebPlayer',{staticClass:"fullwidth d-flex align-center flex-column justify-center",attrs:{"height":"450","stream-url":_vm.item.link_url}}):(_vm.is_link(_vm.item) && _vm.item.embed)?_c('EmbedPlayer',{staticClass:"fullwidth d-flex align-center flex-column justify-center",attrs:{"height":"450","embed":_vm.item.embed}}):(_vm.is_link(_vm.item))?_c('v-sheet',{staticClass:"fullwidth d-flex align-center justify-center",attrs:{"height":"450","color":"transparent"}},[_c('v-img',{attrs:{"lazy-src":_vm.settings.loader,"height":"300","contain":"","src":_vm.getLinkPreview(_vm.item)}})],1):_c('Attachment',{staticClass:"align-self-center",attrs:{"height":"420","item":_vm.item}}),(_vm.item)?_c('v-row',{staticClass:"align-self-start",attrs:{"dense":"","align":"center","justify":"start"}},[_c('v-avatar',{staticClass:"my-2 mr-2",attrs:{"tile":"","size":"40"}},[(_vm.item.uploader)?_c('v-img',{attrs:{"contain":"","src":_vm.item.uploader.image_url}}):_c('v-img',{attrs:{"lazy-src":_vm.settings.loader,"contain":"","src":_vm.settings.notFound}})],1),_c('div',{staticClass:"d-flex primary--text flex-column align-start justify-start"},[_c('strong',[_vm._v(_vm._s(_vm._f("trunc_mid")(_vm.item.filename,30, 30)))]),(_vm.item.uploader)?_c('span',[_vm._v(" Uploaded By: "+_vm._s(_vm.item.uploader.fullname)+" ")]):_vm._e()])],1):_vm._e()],1):_vm._e(),(_vm.annotate_mode)?_c('ImageAnnotator',{staticClass:"col-md-7 col-sm-12 d-flex rounded-5 pa-5 align-stretch justify-center",attrs:{"has-header":false,"show-right-side":false,"attachment":_vm.item}}):_vm._e(),_c('v-sheet',{staticClass:"col-md-5 col-sm-12 d-flex flex-column px-5 py-3",attrs:{"height":"500","tag":"v-col"}},[_c('v-row',{staticClass:"mb-1",attrs:{"dense":"","align":"start","justify":"center"}},[(_vm.canBeAnnotate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none mx-1",attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.annotate_mode = !_vm.annotate_mode}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Annotation Mode : "+_vm._s(_vm.annotate_mode ? 'On' : 'Off')+" ")],1)]}}],null,false,1526198441)},[_c('span',[_vm._v(" Set annotation mode")])]):_vm._e(),(_vm.item)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.canBeDownload)?_c('v-btn',_vm._g(_vm._b({staticClass:"text-none mx-1",attrs:{"color":"primary","depressed":"","href":_vm.item.url}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cloud-download")]),_vm._v(" Download File ")],1):_vm._e()]}}],null,false,2586454571)},[_c('span',[_vm._v("Download File")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.item && _vm.item.category === 'links')?_c('v-btn',_vm._g(_vm._b({staticClass:"text-none mx-1",attrs:{"color":"primary","depressed":"","target":"_blank","href":_vm.item.link_url}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" Go to Link ")],1):_vm._e()]}}],null,false,3192282694)},[_c('span',[_vm._v("Open link")])])],1),(_vm.item)?_c('CommentV2',{staticClass:"mx-auto mb-1 fullwidth",attrs:{"body-max-height":"350","body-min-height":300,"type":"attachment","id":_vm.item.id,"height":420}}):_vm._e()],1)],1)],1):_vm._e()]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}})}
var staticRenderFns = []

export { render, staticRenderFns }