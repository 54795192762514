<template>
  <Dashcard
    :card="card"
    :has-delete="false"
    :card-height="400"
    :loading="loading"
    :has-expand="false"
    @expand-card="expandCard"
  >
    <template v-slot:today>
      <v-card v-if="stats.today.length" flat height="250">
        <v-card-text class="px-1">
          <CustomTimeline :items="stats.today" :display="4"></CustomTimeline>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="250" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No activities today
        </h3>
      </v-card>
    </template>
    <template v-slot:week>
      <v-card v-if="stats.week.length" flat height="250">
        <v-card-text class="px-1">
          <CustomTimeline :items="stats.week" :display="4"></CustomTimeline>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="250" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No activities for this week
        </h3>
      </v-card>
    </template>
    <template v-slot:month>
      <v-card v-if="stats.month.length" flat height="250">
        <v-card-text class="px-1">
          <CustomTimeline :items="stats.month" :display="4"></CustomTimeline>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="250" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No activities for this month
        </h3>
      </v-card>
    </template>
  </Dashcard>
</template>

<script>
import request from "@/services/axios_instance";
import { mapGetters, mapMutations } from "vuex";
import Dashcard from "@/modules/DashboardContent/components/Dashcard.vue";
import ActivityFileViewer from "@/modules/Timeline/components/ActivityFileViewer.vue";
import CustomTimeline from "../../DashboardContent/components/CustomTimeline.vue";
export default {
  name: "ProjectTimelineCard",
  props: {
    card: { type: Object, required: true },
    projectId: { type: [Number, String], required: true },
  },
  data: () => ({
    isExpand: false,
    loading: false,
    // icons: [
    //   require("@/assets/svgs/icons/file_image.svg").default,
    //   require("@/assets/svgs/icons/file_video.svg").default,
    //   require("@/assets/svgs/icons/file_document.svg").default,
    //   require("@/assets/svgs/icons/file_pdf.svg").default,
    //   require("@/assets/svgs/icons/file_spreadsheet.svg").default,
    //   require("@/assets/svgs/icons/file_upload.svg").default,
    // ],
    model: null,
    activeItem: null,
    activeFilter: "all",
    filters: [
      { text: "All", value: "all" },
      { text: "Files", value: "files" },
      { text: "Tasks", value: "project.task" },
    ],
    stats: {
      today: [],
      week: [],
      month: [],
    },
  }),
  components: {
    Dashcard,
    ActivityFileViewer,
    CustomTimeline,
  },
  created() {
    this.getData();
  },
  // watch: {
  //   activeFilter(val) {
  //     this.$store.dispatch("project/fetchProjectTimelines", {
  //       filter: val,
  //       projectId: this.projectId,
  //     });
  //   },
  // },
  computed: {
    ...mapGetters("project", ["project", "id", "timeline_statistic"]),
  },
  methods: {
    ...mapMutations("project", ["toggle_card_expand"]),
    getData() {
      this.loading = true;
      request
        .get(`api/dashboard-contents/project/${this.projectId}/timeline`)
        .then(({ data }) => {
          this.stats = data;
        })
        .finally(() => (this.loading = false));
    },
    expandCard() {
      this.toggle_card_expand(this.card.slug);
    },
    setActiveItem(item) {
      this.activeItem = item;
    },
  },
};
</script>
<style scoped>
>>> .v-tab {
  text-transform: none !important;
}
</style>
<style lang="scss" scoped>
.content {
  height: 250px;
  margin-bottom: 100px;
  overflow: hidden;
  &.expanded {
    height: 300px;
    .timeline-wrapper {
      overflow-y: auto;
      height: 280px;
    }
  }
}
.footer {
  border-top: 1px solid #e0e0e0;
  height: 100px;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  h5 {
    font-weight: bolder;
  }
  h4 {
    font-weight: bold;
  }
}
</style>