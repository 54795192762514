import ProjectTimelineCard from './components/ProjectTimelineCard.vue';
import ProjectOverviewCard from './components/ProjectOverviewCard.vue'
import ProjectTaskCard from './components/ProjectTaskCard.vue'
import ProjectEventCard from './components/ProjectEventCard.vue'
import { mapGetters, mapMutations } from "vuex";

export default {
    components: {
        ProjectOverviewCard,
        ProjectTimelineCard,
        ProjectTaskCard,
        ProjectEventCard,
    },

    props: {
        id: { type: [Number, String], required: true }
    },

    data: () => ({
        taskPath: { name: 'project_preview', query: { tab: 'Task' } }
    }),

    computed: {
        ...mapGetters(['user']),
        ...mapGetters('configs', ['modules']),
        ...mapGetters('project', ['cards']),
        type() {
            return this.$route.params.type || 'project'
        },
        paths() {
            return [
                { text: 'Dashboard', disabled: false, route: { name: 'default-content' } },
                { text: `${this.type} HQ`, disabled: true, route: null }
            ]
        },
        hasInvoiceModule() {
            return this.modules && this.modules['invoices']
        },
        moduleCards() {
            return this.cards;
        }
    },
    created() {
        this.set_project_id(this.id)
        this.set_user_id(this.user.id)
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },
    methods: {
        ...mapMutations('taskCards', ['set_project_id', 'set_user_id'])
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.commit('project/set_id', to.params.id);
            // vm.$store.dispatch("project/fetchProjectTasksStatistic");
            // vm.$store.dispatch("project/fetchProjectEventsStatistic");
            // vm.$store.dispatch("project/fetchProjectTimelineStatistic");
            // vm.$store.dispatch("project/fetchProjectTimelines", { filter: "all" });
            vm.verifyProject(to, from, next)
        })
    }
}