<template>
  <Dashcard
    :card-height="400"
    :card="card"
    :loading="loading"
    :has-delete="false"
    :has-expand="mdAndUp"
    @expand-card="expandCard"
  >
    <template v-slot:default>
      <v-tabs class="tab">
        <v-tab active-class="fw-900" class="primary--text text-none">
          Client
        </v-tab>
        <v-tab active-class="fw-900" class="primary--text text-none">
          Project
        </v-tab>
        <v-tab active-class="fw-900" class="primary--text text-none">
          Date
        </v-tab>
        <v-tab-item class="tab-content">
          <v-row
            dense
            class="fill-height mb-0 pa-1"
            align="center"
            justify="center"
            v-if="!card.expand"
          >
            <v-col cols="6" class="d-flex align-center flex-column">
              <h3 class="text-center mb-2 mt-5 font-weight-bold primary--text">
                {{
                  project.client
                    ? project.client.fullname
                    : project.type === 'personal'
                    ? 'Personal Project'
                    : '' | ucwords
                }}
              </h3>
              <div class="d-flex align-center justify-center mb-2">
                <v-img
                  max-height="120"
                  max-width="120"
                  v-if="
                    project.client &&
                    project.client_company &&
                    project.client_company.company_logo
                  "
                  :src="project.client_company.company_logo"
                  alt=""
                ></v-img>
                <v-img
                  max-height="120"
                  max-width="120"
                  v-else-if="!project.client && project.type === 'personal'"
                  :src="
                    project.creator
                      ? project.creator.image_url
                      : require('@/assets/temp/image-placeholder.png')
                  "
                  alt=""
                ></v-img>
                <v-img
                  v-else
                  height="100"
                  contain
                  :src="require('@/assets/temp/image-placeholder.png')"
                  alt="Company logo"
                ></v-img>
              </div>
              <h3 class="text-center font-weight-bold primary--text">
                {{
                  project.client
                    ? client_name
                    : project.creator
                    ? project.creator.fullname
                    : '' | ucwords
                }}
              </h3>
            </v-col>
            <v-col cols="6" class="text-center mx-auto">
              <v-avatar size="auto" width="100%">
                <v-img
                  :lazy-src="settings.loader"
                  :src="card.image"
                  contain
                  :max-height="250"
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
          <v-row
            dense
            align="center"
            justify="center"
            class="content fill-height mt-1"
            v-else
          >
            <v-col cols="8">
              <v-simple-table border dense class="table mx-5 border-1">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Start Date</td>
                      <td>{{ project.started_at | format('LL') }}</td>
                    </tr>
                    <tr>
                      <td>Created On</td>
                      <td>{{ project.created_at | format('LL') }}</td>
                    </tr>
                    <tr>
                      <td>End Date</td>
                      <td v-if="project.end_at">
                        {{ project.end_at | format('LL') }}
                      </td>
                      <td v-else>Not set</td>
                    </tr>
                    <tr v-if="project.client">
                      <td>Client</td>
                      <td>
                        {{ client_name | ucwords }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Project
                        {{ project.type === 'personal' ? 'Title' : 'Service' }}
                      </td>
                      <td>
                        {{ project.project_name | ucwords }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <span class="d-block"> Description</span>
                        <div class="d-block">
                          {{ project.description || '-' }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="4" class="text-center">
              <v-avatar size="auto" width="100%">
                <v-img
                  :lazy-src="settings.loader"
                  :src="card.image"
                  :max-height="250"
                  contain
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="tab-content">
          <v-row
            dense
            class="content fill-height mb-0"
            style="margin-bottom: 0 !important"
            align="center"
            justify="center"
          >
            <v-col
              cols="6"
              class="d-flex"
              :class="
                mdAndUp
                  ? !card.expand
                    ? 'flex-column'
                    : 'align-center flex-wrap justify-center'
                  : 'flex-column'
              "
            >
              <div class="grow">
                <h3 class="text-center mb-5 fw-900 primary--text">
                  Project Title
                </h3>
                <h2 class="text-center mb-5 fs-18 primary--text">
                  {{ project.project_name }}
                </h2>
              </div>
              <div class="grow">
                <h3 class="text-center mb-5 fw-900 primary--text">
                  Description
                </h3>
                <h2 class="text-center mb-5 fs-18 primary--text">
                  {{ project.description || 'No description' }}
                </h2>
              </div>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-avatar size="auto" width="100%">
                <v-img
                  :lazy-src="settings.loader"
                  :src="card.image"
                  contain
                  :max-height="250"
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="tab-content">
          <v-row
            dense
            class="content fill-height mb-0"
            align="center"
            justify="center"
          >
            <v-col
              class="d-flex"
              :class="
                mdAndUp
                  ? !card.expand
                    ? 'flex-column'
                    : 'align-center flex-wrap justify-center'
                  : 'flex-column'
              "
            >
              <div class="grow">
                <h3 class="text-center mb-5 fw-900 primary--text">
                  Start Date
                </h3>
                <h2 class="text-center mb-5 fs-18 primary--text">
                  {{ project.started_at | format('LL') }}
                </h2>
              </div>
              <div class="grow">
                <h3 class="text-center mb-5 fw-900 primary--text">End Date</h3>
                <h2
                  class="text-center mb-5 fs-18 primary--text"
                  v-if="project.end_at"
                >
                  {{ project.end_at | format('LL') }}
                </h2>
                <h2 class="text-center mb-5 primary--text" v-else>Ongoing</h2>
              </div>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-avatar size="auto" width="100%">
                <v-img
                  :lazy-src="settings.loader"
                  :src="card.image"
                  contain
                  :max-height="250"
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </template>
    <template v-slot:card-footer v-if="!card.expand"> </template>
  </Dashcard>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Dashcard from '@/modules/DashboardContent/components/Dashcard.vue'
export default {
  name: 'ProjectOverviewCard',
  props: {
    card: { type: Object, required: true },
    projectId: { type: [Number, String], required: true }
  },
  data: () => ({
    items: [],
    overview: null
  }),
  components: {
    Dashcard
  },
  computed: {
    ...mapGetters('project', ['project', 'loading']),
    project_name() {
      if (this.project) {
        return this.project.title
          ? this.project.title
          : this.project.service
          ? this.project.service.name
          : ''
      }
      return ''
    },
    client_name() {
      if (this.project) {
        return this.project.client_company
          ? this.project.client_company.name
          : this.project.client
          ? this.project.client.fullname
          : ''
      }
      return ''
    }
  },
  methods: {
    ...mapMutations('project', ['toggle_card_expand']),
    expandCard() {
      this.toggle_card_expand(this.card.slug)
    }
  }
}
</script>
<style lang="scss" scoped>
.tab {
  .tab-content {
    max-height: 250px;
  }
}
.content {
  height: 250px;
  margin-bottom: 100px;
  overflow: hidden;
  .col {
    overflow: hidden;
  }
  .table {
    // border: 1px solid #223a6b;
    border-collapse: collapse;
    tr {
      &:hover {
        background-color: transparent !important;
      }
      td {
        color: #223a6b;
        border: 1px solid #223a6b;
        font-weight: bold;
        line-height: 2em;
        &:nth-child(1) {
          width: 150px;
        }
      }
    }
  }
}
.footer {
  border-top: 1px solid #e0e0e0;
  height: 100px;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  h5 {
    font-weight: bolder;
  }
  h4 {
    font-weight: bold;
  }
}
</style>
