<template>
  <v-card v-bind="$attrs" elevation="4" min-width="350">
    <v-card-title class="text-center">
      <h2 class="primary--text font-weight-bold align-self-center mx-auto">
        {{ event.title | ucwords }}
      </h2>
    </v-card-title>
    <v-card-text class="text-center">
      <v-row dense no-gutters>
        <v-col cols="6" class="subtitle-2 primary--text text-right pr-5">
          Event Duration :
        </v-col>
        <v-col cols="6" class="text-left"> {{ event.event_duration }} </v-col>
        <v-col cols="6" class="subtitle-2 primary--text text-right pr-5">
          Event Type :
        </v-col>
        <v-col cols="6" class="text-left">
          <v-chip
            small
            class="mt-2 caption light--text"
            pill
            :color="event.event_type.color"
          >
            {{ event.event_type.name | ucwords }}
          </v-chip>
        </v-col>
        <v-col cols="6" class="subtitle-2 primary--text text-right pr-5">
          Participants :
        </v-col>
        <v-col cols="6" class="d-flex text-left">
          <Avatars
            class="align-self-center"
            :items="event.users"
            :count="5"
          ></Avatars>
        </v-col>
        <v-col
          cols="6"
          class="subtitle-2 primary--text text-right pr-5"
          v-if="event.description"
        >
          Description :
        </v-col>
        <v-col cols="6" class="text-left" v-if="event.description">
          {{ event.description }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    event: { type: Object, required: true }
  },
  computed: {}
}
</script>

<style lang="scss" scoped></style>
