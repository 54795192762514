<template>
  <CustomDialog
    v-model="open"
    :title="title"
    :max-width="lgAndUp ? 1904 : 1366"
    button1-text="close"
    @button1="close_dialog"
    :has-footer="false"
  >
    <template v-slot:content>
      <v-sheet v-if="item" :key="item.id" height="100%" class="fullwidth">
        <v-row no-gutters dense align="start" justify="space-between">
          <v-sheet
            v-if="!annotate_mode"
            height="500"
            tag="v-col"
            color="#c0c0c0"
            class="col-md-7 col-sm-12 d-flex rounded-5 pa-5 flex-column align-center justify-center"
          >
            <Attachment
              class="align-self-center"
              height="420"
              v-if="canBeViewInAttachment"
              animated
              :item="item"
            ></Attachment>
            <WebPlayer
              v-else-if="
                is_link(item) &&
                (isYouTube(item.link_url) || isVimeo(item.link_url))
              "
              height="450"
              :stream-url="item.link_url"
              class="fullwidth d-flex align-center flex-column justify-center"
            >
            </WebPlayer>
            <EmbedPlayer
              v-else-if="is_link(item) && item.embed"
              height="450"
              :embed="item.embed"
              class="fullwidth d-flex align-center flex-column justify-center"
            >
            </EmbedPlayer>
            <v-sheet
              height="450"
              color="transparent"
              class="fullwidth d-flex align-center justify-center"
              v-else-if="is_link(item)"
            >
              <v-img
                :lazy-src="settings.loader"
                height="300"
                contain
                :src="getLinkPreview(item)"
              >
              </v-img>
            </v-sheet>
            <Attachment
              class="align-self-center"
              height="420"
              v-else
              :item="item"
            ></Attachment>
            <v-row
              v-if="item"
              dense
              class="align-self-start"
              align="center"
              justify="start"
            >
              <v-avatar tile size="40" class="my-2 mr-2">
                <v-img
                  v-if="item.uploader"
                  contain
                  :src="item.uploader.image_url"
                ></v-img>
                <v-img
                  :lazy-src="settings.loader"
                  v-else
                  contain
                  :src="settings.notFound"
                ></v-img>
              </v-avatar>
              <div
                class="d-flex primary--text flex-column align-start justify-start"
              >
                <strong>{{ item.filename | trunc_mid(30, 30) }}</strong>
                <span v-if="item.uploader">
                  Uploaded By: {{ item.uploader.fullname }}
                </span>
              </div>
            </v-row>
          </v-sheet>
          <ImageAnnotator
            :has-header="false"
            :show-right-side="false"
            v-if="annotate_mode"
            :attachment="item"
            class="col-md-7 col-sm-12 d-flex rounded-5 pa-5 align-stretch justify-center"
          ></ImageAnnotator>
          <v-sheet
            height="500"
            tag="v-col"
            class="col-md-5 col-sm-12 d-flex flex-column px-5 py-3"
          >
            <v-row dense align="start" class="mb-1" justify="center">
              <v-tooltip top v-if="canBeAnnotate">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="text-none mx-1"
                    v-bind="attrs"
                    v-on="on"
                    depressed
                    @click="annotate_mode = !annotate_mode"
                  >
                    <v-icon left>mdi-pencil</v-icon>
                    Annotation Mode : {{ annotate_mode ? 'On' : 'Off' }}
                  </v-btn>
                </template>
                <span> Set annotation mode</span>
              </v-tooltip>
              <v-tooltip top v-if="item">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="text-none mx-1"
                    v-bind="attrs"
                    v-on="on"
                    depressed
                    v-if="canBeDownload"
                    :href="item.url"
                  >
                    <v-icon left>mdi-cloud-download</v-icon>
                    Download File
                  </v-btn>
                </template>
                <span>Download File</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="text-none mx-1"
                    v-bind="attrs"
                    v-on="on"
                    depressed
                    target="_blank"
                    v-if="item && item.category === 'links'"
                    :href="item.link_url"
                  >
                    <v-icon left>mdi-chevron-right</v-icon>
                    Go to Link
                  </v-btn>
                </template>
                <span>Open link</span>
              </v-tooltip>
            </v-row>
            <CommentV2
              v-if="item"
              body-max-height="350"
              :body-min-height="300"
              type="attachment"
              :id="item.id"
              :height="420"
              class="mx-auto mb-1 fullwidth"
            ></CommentV2>
          </v-sheet>
        </v-row>
      </v-sheet>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import CommentV2 from '@/common/Comment/CommentV2.vue'
import _ from 'lodash'
import ImageAnnotator from '@/common/Editor/ImageAnnotator.vue'
import EmbedPlayer from '@/common/Players/EmbedPlayer.vue'
import WebPlayer from '@/common/Players/WebPlayer.vue'
export default {
  name: 'ProjectFileViewerDialog',
  components: {
    CommentV2,
    CustomDialog,
    ImageAnnotator,
    EmbedPlayer,
    WebPlayer
  },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object }
  },
  data: () => ({
    open: false,
    drawer: true,
    mode: 'comment',
    annotate_mode: false
  }),
  computed: {
    title() {
      return this.item
        ? this.item.title
          ? this.item.title
          : this.item.filename
        : 'File Viewer'
    },
    canBeViewInAttachment() {
      return this.item && ['images', 'video'].includes(this.item.category)
    },
    canBeAnnotate() {
      return (
        this.item &&
        ['png', 'jpg', 'gif', 'jpeg', 'webp'].includes(this.item.file_ext)
      )
    },
    canBeDownload() {
      return this.item && !['links'].includes(this.item.category)
    }
  },
  watch: {
    value(val) {
      this.open = val
      this.annotate_mode = false
    },
    open(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    close_dialog() {
      this.open = false
    }
  }
}
</script>

<style scoped lang="scss">
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  top: 0px;
  cursor: pointer;
  background: transparent;
}

.switch {
  position: absolute;
  z-index: 5;
}
</style>
