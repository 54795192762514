<template>
  <Dashcard
    :card-height="400"
    :card="card"
    :loading="loading"
    :has-delete="false"
    :has-expand="false"
    @expand-card="expandCard"
  >
    <template v-slot:header-right>
      <v-btn
        @click="$router.push({ name: 'project-preview-tasks' })"
        color="primary"
        depressed
        small
        class="text-none"
      >
        Go to Tasks
        <v-icon right>mdi-arrow-right</v-icon>
      </v-btn>
    </template>
    <template v-slot:today>
      <v-card class="d-flex align-center justify-center" v-if="stats.today.length" flat height="250">
        <v-card-text class="px-1">
          <CustomTaskSlider :items="stats.today"></CustomTaskSlider>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="250" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No updated or created task today
        </h3>
      </v-card>
    </template>
    <template v-slot:week>
      <v-card class="d-flex align-center justify-center" v-if="stats.week.length" flat height="250">
        <v-card-text class="px-1">
          <CustomTaskSlider :items="stats.week"></CustomTaskSlider>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="250" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No updated or created task for this week
        </h3>
      </v-card>
    </template>
    <template v-slot:month>
      <v-card class="d-flex align-center justify-center" v-if="stats.month.length" flat height="250">
        <v-card-text class="px-1 d-flex align-center justify-center">
          <CustomTaskSlider :items="stats.month"></CustomTaskSlider>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="250" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No updated or created task for this month
        </h3>
      </v-card>
    </template>
  </Dashcard>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import request from "@/services/axios_instance";
import Dashcard from "@/modules/DashboardContent/components/Dashcard.vue";
import CustomTaskSlider from "../../DashboardContent/components/CustomTaskSlider.vue";
export default {
  name: "ProjectTaskCard",
  props: {
    card: { type: Object, required: true },
    projectId: { type: [Number, String], required: true },
  },
  components: {
    Dashcard,
    CustomTaskSlider,
  },
  data: () => ({
    loading: false,
    stats: {
      today: [],
      week: [],
      month: [],
    },
  }),
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters("project", ["project", "tasks_statistic"]),
  },
  methods: {
    ...mapMutations("project", ["toggle_card_expand"]),
    getData() {
      this.loading = true;
      request
        .get(`api/dashboard-contents/project/${this.projectId}/tasks`)
        .then(({ data }) => {
          this.stats = data;
        })
        .finally(() => (this.loading = false));
    },
    expandCard() {
      this.toggle_card_expand(this.card.slug);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>