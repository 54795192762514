<template>
  <v-sheet flat class="files-preview">
    <v-slide-group v-bind="$attrs" v-model="slider" center-active show-arrows>
      <v-slide-item
        v-for="(attachment, index) in cloneItems"
        :key="index"
        :value="attachment"
        class="py-5"
        elevation="5"
        v-slot:default="{ active, toggle }"
      >
        <v-avatar
          tile
          :color="active ? 'primary' : 'grey lighten-1'"
          class="ma-3 cursor-pointer"
          :size="size"
          @click="toggle"
        >
          <v-img
            :lazy-src="settings.loader"
            contain
            :src="getSrc(attachment)"
          ></v-img>
        </v-avatar>
      </v-slide-item>
    </v-slide-group>
    <ProjectFileViewerDialog
      v-model="view_dialog"
      :item="activeItem"
    ></ProjectFileViewerDialog>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import ProjectFileViewerDialog from '@/modules/ProjectPreview-Files/components/ProjectFileViewerDialog.vue'
export default {
  components: { ProjectFileViewerDialog },
  name: 'ActivityFileViewer',
  props: {
    activity: Object,
    size: { type: [Number, String], default: 'auto' }
  },
  data: () => ({
    slider: null,
    activeItem: null,
    view_dialog: false
  }),
  computed: {
    cloneItems() {
      return _.cloneDeep(this.activity.attachments)
    }
  },
  watch: {
    slider: {
      handler: function (val) {
        this.activeItem = val
        if (val) {
          this.view_dialog = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    hasImage(file) {
      if (file.category === 'images') return true
      if (file.props && file.props.image) return true
      return false
    },
    getSrc(file) {
      if (this.hasImage(file)) {
        return file.category === 'images' ? file.thumbnail : file.props.image
      }
      return this.settings.noImagePreview
    },
    itemIsImageOrVideo(file) {
      return ['videos', 'images'].includes(file.category)
    }
  }
}
</script>
<style lang="scss" scoped></style>
